<template>
  <div>
    <div
      class="form-group row mb-4"
    >
      <label class="col-12 col-md-2">{{ $t('theme') }}</label>
      <div class="col-12 col-md-10">
        <select
          v-model="selectedTheme"
          :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('name') }]"
          @change="getInstallationCount"
        >
          <option
            :value="null"
            disabled
          >
            {{ $t('pleaseSelectOne') }}
          </option>
          <option
            v-for="(theme, themeIndex) in themes"
            :key="`themeInstallationConfigRefreshAll-${ themeIndex }`"
            :value="theme"
          >
            {{ theme.name }}
          </option>
        </select>
        <span
          v-show="errors.has('name')"
          class="badge badge-danger"
        >{{ errors.first('name') }}</span>
      </div>
    </div>
    <div
      class="form-group row mb-4"
    >
      <label
        class="col-9"
      >{{ $t('installationConfigurationRefresh.refreshAffect') }} {{ affectedInstallations ? affectedInstallations : 0 }} {{ $t('installations') }}!</label>
      <button
        class="btn btn-primary float-right col-3"
        :disabled="selectedTheme == null"
        @click="refresh()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="sync-alt"
        />{{ $t('installationConfigurationRefresh.refresh') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstallationConfigRefreshAll',
  data () {
    return {
      themes: null,
      affectedInstallations: 0,
      selectedTheme: null
    }
  },
  created () {
    this.getThemes();
  },
  methods: {
    getThemes () {
      this.axios.get('/VideoPostProcessing/GetThemes')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
         }
          this.themes  = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInstallationCount () {
      this.axios.get(`/VideoPostProcessing/GetInstallationCountFromTheme?themeId=${ this.selectedTheme.id }`)
      .then((response) => {
        this.affectedInstallations = response.data;
       })
    },
    refresh () {
      this.axios.post(`/VideoPostProcessing/ReloadAllConfigs?themeId=${ this.selectedTheme.id }`)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.status) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.$snotify.success(this.$t('installationConfigurationRefresh.successfulyRefreshed'));
          this.$emit('refresh');
        });
    }
  }
}
</script>

